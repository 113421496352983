* {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

.B2wDDG_dashboardContainer .B2wDDG_header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 50px;
  display: flex;
}

.B2wDDG_dashboardContainer .B2wDDG_header .B2wDDG_logoutBtn {
  cursor: pointer;
}

.B2wDDG_dashboardContainer .B2wDDG_header .B2wDDG_logoutBtn:hover {
  color: #000;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer {
  flex-direction: column;
  width: 900px;
  display: flex;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 30px 40px;
  display: flex;
  box-shadow: 0 0 0 1px #0000000d;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer .B2wDDG_info {
  font-size: 12px;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer .B2wDDG_listItem {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer .B2wDDG_listItem .B2wDDG_buttonsArea {
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer .B2wDDG_listItem .B2wDDG_buttonsArea .B2wDDG_copyBtn {
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  padding: 10px 15px;
  display: flex;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer .B2wDDG_listItem .B2wDDG_buttonsArea .B2wDDG_copyBtn:hover {
  color: #fff;
  background-color: #000;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer .B2wDDG_listItem .B2wDDG_buttonsArea .B2wDDG_remove {
  cursor: pointer;
}

.B2wDDG_dashboardContainer .B2wDDG_contentContainer .B2wDDG_tokenListContainer .B2wDDG_itemsContainer .B2wDDG_listItem .B2wDDG_buttonsArea .B2wDDG_remove:hover {
  color: red;
}

.B2wDDG_button37 {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 4px;
  outline: 0;
  margin-bottom: 50px;
  padding: 10px 25px;
  font-family: Akzidenz Grotesk BQ Medium, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  transition: transform .15s, box-shadow .15s;
  transform: translateY(0);
  box-shadow: 0 2px 4px #0000001a;
}

.B2wDDG_button37:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 9px #00000026;
}

@media (width >= 768px) {
  .B2wDDG_button37 {
    padding: 10px 30px;
  }
}

.B2wDDG_loginContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.B2wDDG_loginContainer .B2wDDG_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  display: flex;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_logo {
  padding-bottom: 38px;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox {
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  padding: 50px 70px;
  font-family: Poppins;
  display: flex;
  box-shadow: 0 0 102.5px #0000001a;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox a {
  color: #007dfa;
  text-decoration: none;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox a:hover {
  color: #0064c8;
  text-decoration: underline;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox .B2wDDG_title {
  color: #101012;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 25px;
  display: flex;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox form .B2wDDG_inputArea {
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox form .B2wDDG_inputArea label {
  color: #5a5a5d;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox form .B2wDDG_inputArea input {
  box-sizing: border-box;
  color: #5a5a5d;
  border: 1px solid #bebebf;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox form .B2wDDG_submitBtn {
  color: #fff;
  background-color: #007dfa;
  border: 0;
  border-radius: 8px;
  width: 100%;
  height: 52px;
  margin-top: 25px;
  padding: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox form .B2wDDG_submitBtn:hover {
  cursor: pointer;
  background-color: #0064c8;
}

.B2wDDG_loginContainer .B2wDDG_container .B2wDDG_mainBox form .B2wDDG_boxFooter {
  color: #bebebf;
  margin-top: 20px;
}

h2 {
  margin: 0;
  padding: 0;
}

.B2wDDG_noTokens {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  display: flex;
}

/*# sourceMappingURL=index.c35ba27f.css.map */
